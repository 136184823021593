import type { CouponBrowseResult } from "@/dao/coupon";
import type { ProductGetResult } from "@/dao/product";
import { PERCENT_PRECISION } from "@/db/dbutils";
import { formatMoney } from "@/lib/currencies";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export const isNotEmpty = <V>(value: Array<V | null | undefined>): value is Array<V> =>
	!!value.filter(Boolean).length;

export const isDefined = <T>(value: T | null | undefined): value is T =>
	value !== null && value !== undefined;
export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export const bigIntMax = (...args: (bigint | string)[]) => args.map(BigInt).reduce((m, e) => (e > m ? e : m));
export const bigIntMin = (...args: (bigint | string)[]) => args.map(BigInt).reduce((m, e) => (e < m ? e : m));

/**
 * Function to easily allow for autocompletion of TailwindCSS in template literals.
 */
export const tw = String.raw;

export const formatDate = (date: Date | number | string) => {
	return new Intl.DateTimeFormat("en-US", {
		dateStyle: "long",
		timeStyle: "long",
	}).format(new Date(date));
};
export const formatDate2 = (date: Date | number | string) => {
	return new Intl.DateTimeFormat("en-US", {
		dateStyle: "short",
		timeStyle: "short",
	}).format(new Date(date));
};

export const getSubdomain = (name: string, apexName: string) => {
	if (name === apexName) {
		return null;
	}
	return name.slice(0, name.length - apexName.length - 1);
};

export function assertUnreachable(value: never): never {
	throw new Error(
		`assertUnreachable was thrown. Looks like you've forgotten to tak care of this value (probably in a switch…case): ${value as string}`,
	);
}

/**
 * Trigger the event to bubble up to the form to update `isDirty`.
 * In order to do that, we need to update the `value` property of the input
 * using the native setter. If we use `inputRef.current.value = category`,
 * it'll only call React's synthetic event handler, not the native one.
 * @see https://stackoverflow.com/a/46012210/704894
 */
export function triggerNativeEvent(element: HTMLElement, value: string) {
	const event = new Event("input", { bubbles: true });
	Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")?.set?.call(element, value);
	element.dispatchEvent(event);
}

export const safeJsonParse = (str: string | null | undefined): unknown => {
	if (str === null || str === undefined) {
		return null;
	}
	try {
		return JSON.parse(str);
	} catch {
		return null;
	}
};

type PromiseToTupleResult<T> = [Error, null] | [null, Awaited<T>];
export const unpackPromise = async <T extends Promise<unknown>>(
	promise: T,
): Promise<PromiseToTupleResult<T>> => {
	try {
		const result = await promise;
		return [null, result];
	} catch (maybeError) {
		const error = maybeError instanceof Error ? maybeError : new Error(String(maybeError));
		return [error, null];
	}
};

type CardinalWords = Partial<Record<Intl.LDMLPluralRule, string>> & { other: string };
export const pluralize = (count: number, words: CardinalWords) => {
	const cardinalRules = new Intl.PluralRules("en-US");
	const rule = cardinalRules.select(count);
	return words[rule] ?? words.other;
};

const getFieldsByPrefix = <Prefix extends string, Obj extends object>(obj: Obj, prefix: Prefix) => {
	const prefixWithDot = prefix + ".";
	return Object.fromEntries(
		Object.entries(obj)
			.filter(([key]) => key.startsWith(prefixWithDot))
			.map(([key, value]) => [key.slice(prefixWithDot.length), value]),
	) as {
		[K in keyof Obj as K extends `${Prefix}.${infer Key}` ? Key : never]: Obj[K];
	};
};

const addPrefixToFields = <Prefix extends string, Obj extends object>(obj: Obj, prefix: Prefix) => {
	const prefixWithDot = prefix + ".";
	return Object.fromEntries(Object.entries(obj).map(([key, value]) => [prefixWithDot + key, value])) as {
		[K in keyof Obj as `${Prefix}.${K & string}`]: Obj[K];
	};
};

export const slugify = (text: string) => {
	return text
		.toString() // Cast to string (optional)
		.normalize("NFKD") // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
		.toLowerCase() // Convert the string to lowercase letters
		.trim() // Remove whitespace from both sides of a string (optional)
		.replace(/\s+/g, "-") // Replace spaces with -
		.replace(/[^\w-]+/g, "") // Remove all non-word chars
		.replace(/_/g, "-") // Replace _ with -
		.replace(/--+/g, "-") // Replace multiple - with single -
		.replace(/-$/g, ""); // Remove trailing -
};

export const capitalize = (str: string) => (str[0] ? str[0].toUpperCase() + str.slice(1) : "");

export const deslugify = (slug?: string) => {
	if (!slug) {
		return slug;
	}
	return slug
		.split(/[-_]/)
		.map((part) => capitalize(part))
		.join(" ");
};

export const extractFileExtension = (url: string) => {
	const extension = url.split(".").pop();
	if (!extension || extension.length > 4) {
		return undefined;
	}
	return extension;
};

export const unique = <T>(array: T[]) => Array.from(new Set(array));

export const formatProductName = (
	productName: string,
	productVariant: ProductGetResult["variants"][number] | null | undefined,
) => {
	const variants = productVariant?.combinations
		.map((c) => [c.variantValue.variantType.label, c.variantValue.value].join(": ").trim())
		.join(", ")
		.trim();

	if (!variants) {
		return productName;
	}
	return `${productName} (${variants})`;
};

export function invariant(
	condition: unknown,
	message: string,
	ErrorClass?: {
		new (message: string): unknown;
	},
): asserts condition {
	if (!condition) {
		if (ErrorClass) {
			throw new ErrorClass(message);
		}
		throw new Error(message);
	}
}

export const formatRelativeDate = (locale: string, date_: Date | string | number) => {
	const date = new Date(date_).getTime();
	const now = Date.now();
	const millisAgo = now - date;

	const timeUnits = [
		{ unit: "year", millis: 365 * 86400 * 1000 },
		{ unit: "month", millis: 30 * 86400 * 1000 },
		{ unit: "week", millis: 7 * 86400 * 1000 },
		{ unit: "day", millis: 86400 * 1000 },
		{ unit: "hour", millis: 3600 * 1000 },
		{ unit: "minute", millis: 60 * 1000 },
		{ unit: "second", millis: 1000 },
	] as const;

	const foundUnit = timeUnits.find(({ millis }) => millisAgo >= millis) || timeUnits[timeUnits.length - 1];
	if (!foundUnit) {
		return "just now";
	}
	const { unit, millis } = foundUnit;

	return new Intl.RelativeTimeFormat(locale, { numeric: "auto" }).format(
		-Math.floor(millisAgo / millis),
		unit,
	);
};

export const getVariantImages = (variant: { images: string[]; product?: { images: string[] } }) => {
	return variant.images.length ? variant.images : (variant.product?.images ?? []);
};

export const getProductImages = (product: { images: string[]; variants?: { images: string[] }[] }) => {
	return product.images.length ? product.images : (product.variants?.flatMap((v) => v.images) ?? []);
};

export const formatCoupon = ({
	coupon,
	currency,
	locale,
}: {
	coupon: Pick<CouponBrowseResult[number], "type" | "value">;
	currency: string;
	locale: string;
}) => {
	if (coupon.type === "percentage") {
		return `${Number.parseFloat(coupon.value) / Number(PERCENT_PRECISION)}% off`;
	}

	return formatMoney({
		amount: coupon.value,
		currency,
		locale,
	});
};

export function generateRandomWord(length: number) {
	const consonants = "bcdfghjklmnpqrstvwxyz";
	const vowels = "aeiou";

	let word = "";
	let useConsonant = Math.random() > 0.5;

	for (let i = 0; i < length; i++) {
		if (useConsonant) {
			word += consonants.charAt(Math.floor(Math.random() * consonants.length));
		} else {
			word += vowels.charAt(Math.floor(Math.random() * vowels.length));
		}
		useConsonant = !useConsonant;
	}

	return word;
}

export function generateTwoEnglishLikeWords() {
	const word1 = generateRandomWord(Math.floor(Math.random() * 3) + 3);
	const word2 = generateRandomWord(Math.floor(Math.random() * 4) + 4);
	return `${word1}-${word2}`;
}
