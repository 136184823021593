import { PERCENT_PRECISION } from "@/db/dbutils";
import { invariant } from "./utils";

type MoneyDecimal = { amount: string; currency: string };
type MoneyInt = { amount: string | bigint | number; currency: string };

export const getDecimalsForCurrency = (currency: string) => {
	invariant(currency.length === 3, "currency needs to be a 3-letter code");

	const stripeDecimals = edgeCaseCurrencies[currency.toUpperCase() as EdgeCaseCurrenciesSymbol] as
		| EdgeCaseCurrencies[EdgeCaseCurrenciesSymbol]
		| undefined;
	const decimals = stripeDecimals ?? 2;
	return decimals;
};

export const getMultiplierForCurrency = (currency: string) => {
	const decimals = getDecimalsForCurrency(currency);
	return (10 ** decimals) as 1 | 100 | 1000;
};

export const getIntAmountFromDecimal = ({ amount: major, currency }: MoneyDecimal) => {
	const multiplier = getMultiplierForCurrency(currency);
	return BigInt(Math.round(Number.parseFloat(major) * Number(multiplier)).toString());
};

export const getDecimalAmountFromInt = ({ amount: minor, currency }: MoneyInt) => {
	const multiplier = getMultiplierForCurrency(currency);
	return (Number(minor) / multiplier).toFixed(getDecimalsForCurrency(currency));
};

export const formatMoney = ({ amount: minor, currency, locale }: MoneyInt & { locale: string }) => {
	const amount = getDecimalAmountFromInt({ amount: minor, currency });
	return new Intl.NumberFormat(locale, {
		style: "currency",
		currency,
		currencyDisplay: "symbol",
	}).format(Number.parseFloat(amount));
};

export const getSideOfSymbol = ({ currency, locale }: { currency: string; locale: string }) => {
	const formatted = new Intl.NumberFormat(locale, {
		style: "currency",
		currency,
		currencyDisplay: "narrowSymbol",
	}).format(0);

	const symbol = getNarrowSymbol({ currency, locale });
	if (formatted.startsWith(symbol)) {
		return "start" as const;
	} else {
		return "end" as const;
	}
};

export const getNarrowSymbol = ({ currency, locale }: { currency: string; locale: string }) => {
	return new Intl.NumberFormat(locale, {
		style: "currency",
		currency,
		currencyDisplay: "narrowSymbol",
	})
		.format(0)
		.replace(/[\d.,]/g, "")
		.trim();
};

export const formatPercentage = (value: bigint | string | number) => {
	const amount = (Number(value) / Number(PERCENT_PRECISION)).toFixed(3);
	if (amount.endsWith("0")) {
		// if last digit is 0, remove it, i.e. 23.000 -> 23.00
		// but keep the 3-digit precision for edge cases, i.e. NYC 8.875%
		return `${amount.slice(0, -1)}%`;
	}
	return `${amount}%`;
};

// https://docs.stripe.com/currencies#zero-decimal
const edgeCaseCurrencies = {
	BIF: 0,
	CLP: 0,
	DJF: 0,
	GNF: 0,
	JPY: 0,
	KMF: 0,
	KRW: 0,
	MGA: 0,
	PYG: 0,
	RWF: 0,
	UGX: 0,
	VND: 0,
	VUV: 0,
	XAF: 0,
	XOF: 0,
	XPF: 0,

	BHD: 3,
	JOD: 3,
	KWD: 3,
	OMR: 3,
	TND: 3,
} as const;
type EdgeCaseCurrencies = typeof edgeCaseCurrencies;
type EdgeCaseCurrenciesSymbol = keyof EdgeCaseCurrencies;

// https://docs.stripe.com/currencies#presentment-currencies
export const supportedCurrencies = ["PLN", "USD"] as const;
